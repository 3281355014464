@import "~@securitas/react-common-components/theme/includes";

.app {
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid theme-color(primary);
  border-radius: 16px;

  transform: scale(1);
  transition: theme-transition(all);

  &:hover {
    transform: scale(1.05);
    box-shadow: shadow(x-400);
    text-decoration: none;
    background-color: color(blue-500);
  }
}

.icon {
  font-size: 100px;
  flex: 0 0 auto;
  margin-bottom: spacer(2);
}

.name {
  font-size: 16px;
  font-weight: 600;
}
