@import "~@securitas/react-common-components/theme/includes";

.icon-text-align {
  margin-top: -0.1666em;
}

.btn-bg-white {
  background-color: #fff;
}

.btn-text-dark {
  border-color: color(black-200);
  color: color(black-200);
  font-weight: 500;
}
